const toggle = (state = 'notes', action) => {
  switch (action.type) {
    case 'TOGGLE':
      return action.toggle;
    default:
      return state;
  }
}

export default toggle;
