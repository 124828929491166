import React, { Component } from 'react';
import { TUNINGS } from '../../../src/constants/tunings';
import '../scale-select/select.css';
import _ from 'lodash';

export default class TuningSelect extends Component {
  render() {
    return (
      <div className='select-container u-flex-none'>
        <div className='select-container__label u-flex-direction-row'>
          { _.find(TUNINGS, { name: this.props.value }).label }
        </div>

        <select className='select-container__select'
        id='tuning-select'
        value={ this.props.value }
        onChange={ this.props.onChange }>
          { TUNINGS.map((tuning, index) => {
            return <option key={index} value={ tuning.name }>{ tuning.label }</option>
          }) }
        </select>
      </div>
    );
  }
}
