const handToggle = (state = 'right', action) => {
  switch (action.type) {
    case 'HAND_TOGGLE':
      return action.handToggle;
    default:
      return state;
  }
}

export default handToggle;
