import { INTERVAL_NAMES } from './intervals';

export const SCALES = [
  {
    name: 'major',
    label: 'Major',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MAJOR_SECOND,
      INTERVAL_NAMES.MAJOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MAJOR_SIXTH,
      INTERVAL_NAMES.MAJOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MAJOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ]
  },
  {
    name: 'minor',
    label: 'Minor',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MAJOR_SECOND,
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MINOR_SIXTH,
      INTERVAL_NAMES.MINOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'pentatonic_major',
    label: 'Pentatonic major',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MAJOR_SECOND,
      INTERVAL_NAMES.MAJOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MAJOR_SIXTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MAJOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'pentatonic_minor',
    label: 'Pentatonic minor',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MINOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'melodic_minor',
    label: 'Melodic minor',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MAJOR_SECOND,
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MAJOR_SIXTH,
      INTERVAL_NAMES.MAJOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'harmonic_minor',
    label: 'Harmonic minor',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MAJOR_SECOND,
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MINOR_SIXTH,
      INTERVAL_NAMES.MAJOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'blues',
    label: 'Blues',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.DIMINISHED_FIFTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MINOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'ionian',
    label: 'Ionian',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MAJOR_SECOND,
      INTERVAL_NAMES.MAJOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MAJOR_SIXTH,
      INTERVAL_NAMES.MAJOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MAJOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'dorian',
    label: 'Dorian',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MAJOR_SECOND,
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MAJOR_SIXTH,
      INTERVAL_NAMES.MINOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'phrygian',
    label: 'Phrygian',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MINOR_SECOND,
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MINOR_SIXTH,
      INTERVAL_NAMES.MINOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'lydian',
    label: 'Lydian',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MAJOR_SECOND,
      INTERVAL_NAMES.MAJOR_THIRD,
      INTERVAL_NAMES.AUGMENTED_FOURTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MAJOR_SIXTH,
      INTERVAL_NAMES.MAJOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MAJOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'mixolydian',
    label: 'Mixolydian',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MAJOR_SECOND,
      INTERVAL_NAMES.MAJOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MAJOR_SIXTH,
      INTERVAL_NAMES.MINOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MAJOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'aeolian',
    label: 'Aeolian',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MAJOR_SECOND,
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.PERFECT_FIFTH,
      INTERVAL_NAMES.MINOR_SIXTH,
      INTERVAL_NAMES.MINOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FIFTH,
    ],
  },
  {
    name: 'locrian',
    label: 'Locrian',
    intervals: [
      INTERVAL_NAMES.ROOT,
      INTERVAL_NAMES.MINOR_SECOND,
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.PERFECT_FOURTH,
      INTERVAL_NAMES.DIMINISHED_FIFTH,
      INTERVAL_NAMES.MINOR_SIXTH,
      INTERVAL_NAMES.MINOR_SEVENTH,
    ],
    triadIntervals: [
      INTERVAL_NAMES.MINOR_THIRD,
      INTERVAL_NAMES.DIMINISHED_FIFTH,
    ],
  },
];
