import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './hand-toggle.css';

export default class HandToggle extends Component {
  render() {
    return (
      <div className='u-flex-direction-row'>
        <label className={ classNames('hand-toggle__label u-margin-R', {
          'is-active': this.props.value === 'left',
        }) }>
          <input type="radio"
          className='u-display-none'
          name="toggle"
          id="left"
          value="left"
          onChange={ this.props.onChange }
          checked={ this.props.value === 'left' } />

          <div className="u-flex-direction-row u-align-items-center u-relative">
            L

            <img
              src={
                this.props.value === 'left' ?
                  'left-hand-icon.svg' : 'left-hand-icon-inactive.svg'
              }
            alt="left hand"
            className="hand-toggle__icon  hand-toggle__icon--left"
            />
          </div>

        </label>

        <label className={ classNames('hand-toggle__label', {
          'is-active': this.props.value === 'right',
        }) }>
          <input type="radio"
          className='u-display-none'
          name="toggle"
          id="right"
          value="right"
          onChange={ this.props.onChange }
          checked={ this.props.value === 'right' } />

          <div className="u-flex-direction-row u-align-items-center
          u-justify-content-flex-end u-relative">
            <img
            src={
              this.props.value === 'right' ?
                'right-hand-icon.svg' : 'right-hand-icon-inactive.svg'
            }
            alt="right hand"
            className="hand-toggle__icon hand-toggle__icon--right"
            />

            R
          </div>
        </label>
      </div>
    );
  }
}

HandToggle.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
