// get list of notes for that string using the open fret note
// then use the scale & key to figure out what should be true about each note
import _ from 'lodash';
import { SCALES } from '../../constants/scales';
import { INTERVAL_NAMES, INTERVALS } from '../../constants/intervals';
import { NOTES } from '../../constants/notes';
import { getNotesAndIntervals } from '../get-notes-and-intervals/get-notes-and-intervals';

function getScaleIntervals(scale) {
  return _.get(_.find(SCALES, { name: scale }), 'intervals');
}

function getScaleTriadIntervals(scale) {
  return _.get(_.find(SCALES, { name: scale }), 'triadIntervals');
}

function getScaleNotes(scale, key) {
  return _.map(getScaleIntervals(scale), (scaleInterval) => {
    let notes = _.find(INTERVALS, { name: scaleInterval }).notes;
    return _.find(notes, { key });
  });
}

function getNoteAndEquivalents(note) {
  return _.concat(
    _.find(NOTES, { name: note }).name,
    _.find(NOTES, { name: note }).equivalents,
  );
}

function loopObjectFromIndex(object, index) {
  return _.concat(
    object.slice(index),
    object.slice(0, index + 1)
  );
}

function listNotesFromNoteOrEquivalent(key, initialNote, notesAndIntervals) {
  const note = _.intersection(
    getNoteAndEquivalents(initialNote),
    _.map(notesAndIntervals, (item) => item.note),
  );

  const offset = _.findIndex(notesAndIntervals, { note: _.head(note) });
  return loopObjectFromIndex(notesAndIntervals, offset);
}

function doesScaleContainDimFifth(scale) {
  return _.includes(getScaleIntervals(scale), INTERVAL_NAMES.DIMINISHED_FIFTH);
}

function isAllowedNote(note, scale, key) {
  return _.some(getScaleNotes(scale, key), { note });
}

function isTriadNote(interval, scale) {
  return _.includes(getScaleTriadIntervals(scale), interval)
}

function presentNotesAndIntervals(notesAndIntervals, scale) {
  return doesScaleContainDimFifth(scale) ?
    _.reject(notesAndIntervals, { interval: INTERVAL_NAMES.AUGMENTED_FOURTH }) :
    _.reject(notesAndIntervals, { interval: INTERVAL_NAMES.DIMINISHED_FIFTH });
}

function getNoteLabel(note) {
  return _.find(NOTES, { name: note }).label;
}

function getIntervalLabel(interval) {
  return _.find(INTERVALS, { name: interval }).label;
}

export function getFrets(key, scale, initialNote) {
  const notesAndIntervals = presentNotesAndIntervals(
    getNotesAndIntervals(key), scale
  );

  return _.map(listNotesFromNoteOrEquivalent(key, initialNote, notesAndIntervals), (item) => {
    return {
      note: getNoteLabel(item.note),
      interval: getIntervalLabel(item.interval),
      isHighlighted: item.interval === INTERVAL_NAMES.ROOT,
      isVisible: isAllowedNote(item.note, scale, key),
      isTriad: isTriadNote(item.interval, scale),
    }
  });
}
