const tuning = (state = 'standard', action) => {
  switch (action.type) {
    case 'TUNING':
      return action.tuning;
    default:
      return state;
  }
}

export default tuning;
