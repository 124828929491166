const note = (state = 'c', action) => {
  switch (action.type) {
    case 'NOTE':
      return action.note;
    default:
      return state;
  }
}

export default note;
