const strings = (state = ['e', 'a', 'd', 'g', 'b', 'e'], action) => {
  switch (action.type) {
    case 'STRINGS':
      return action.strings;
    default:
      return state;
  }
}

export default strings;
