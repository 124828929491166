import React, { Component } from 'react';
import Fret from '../../../src/components/fret/fret';
import './string.css';

// TODO: Rename from 'String', confusing as it is a reserved class
export default class String extends Component {
  render() {
    return (
      <div className='string u-flex-none'>
        {
          this.props.frets.map((fret, index) => {
            return (
              <Fret key={ index }
              isHighlighted={ fret.isHighlighted }
              isVisible={ fret.isVisible }
              isTriad={ fret.isTriad }
              interval={ fret.interval }
              note={ fret.note } />
            );
          })
        }
      </div>
    );
  }
}
