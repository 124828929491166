import React from 'react';
import App from '../src/components/app/app';
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import reducer from './reducers'
import './index.css';
import { createRoot } from 'react-dom/client';

const store = configureStore({reducer});
console.log('store:', store)

createRoot(document.getElementById('root')).render(
  <Provider store={ store }>
    <App />
  </Provider>
);
