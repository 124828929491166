import React, { Component } from 'react';
import { VERSION_NUMBER } from '../../../src/constants/version-number';
import './help-modal.css';

export default class HelpModal extends Component {
  render() {
    return (
      <div id='help-modal'
      className='help-modal__container' onClick={ this.props.onClick }>
        <div className='help-modal__content'>
          <img className='help-modal__close-button'
          alt='Close button'
          onClick={ this.props.onClose }
          src='close-icon.svg'/>

          <p className='u-margin-B'>
            <strong>frets.fyi</strong>
          </p>

          <p className='u-margin-B'>
            <a className='u-inline-code u-link-clean u-text-xxs'
            href='https://github.com/jamesshedden/frets-fyi/releases'
            target='_blank'
            rel="noopener noreferrer">
              { VERSION_NUMBER }
            </a>
          </p>

          <p>
            Made by&nbsp;

            <a className='u-link'
            href='https://twitter.com/jamesshedden'
            target='_blank'
            rel="noopener noreferrer">
              @jamesshedden
            </a>
          </p>
        </div>
      </div>
    );
  }
}
