import _ from 'lodash';
import { INTERVALS } from '../../constants/intervals';

export function getNotesAndIntervals(note) {
  return _.map(INTERVALS, (interval) => {
    return {
      interval: interval.name,
      note: _.find(interval.notes, { key: note }).note,
    }
  });
}
