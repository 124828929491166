import React, { Component } from 'react';
import { NOTES } from '../../../src/constants/notes';
import '../scale-select/select.css';
import _ from 'lodash';

export default class NoteSelect extends Component {
  render() {
    return (
      <div className='select-container u-flex-none u-margin-R'>
        <div className='select-container__label u-flex-direction-row'>
          { _.find(NOTES, { name: this.props.value }).label }
        </div>

        <select className='select-container__select'
        id='note-select'
        value={ this.props.value }
        onChange={ this.props.onChange }>
          { _.filter(NOTES, { hasScales: true }).map((note, index) => {
            return <option key={index} value={ note.name }>{ note.label }</option>
          }) }
        </select>
      </div>
    );
  }
}
