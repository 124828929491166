const isModalVisible = (state = false, action) => {
  switch (action.type) {
    case 'IS_MODAL_VISIBLE':
      return action.isModalVisible;
    default:
      return state;
  }
}

export default isModalVisible;
