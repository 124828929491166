const scale = (state = 'major', action) => {
  switch (action.type) {
    case 'SCALE':
      return action.scale;
    default:
      return state;
  }
}

export default scale;
