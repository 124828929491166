export const NOTE_NAMES = {
  A_DOUBLE_FLAT: 'a_double_flat',
  A_FLAT: 'a_flat',
  A: 'a',
  A_SHARP: 'a_sharp',
  B_DOUBLE_FLAT: 'b_double_flat',
  B_FLAT: 'b_flat',
  B: 'b',
  B_SHARP: 'b_sharp',
  C_FLAT: 'c_flat',
  C: 'c',
  C_SHARP: 'c_sharp',
  C_DOUBLE_SHARP: 'c_double_sharp',
  D_DOUBLE_FLAT: 'd_double_flat',
  D_FLAT: 'd_flat',
  D: 'd',
  D_SHARP: 'd_sharp',
  D_DOUBLE_SHARP: 'd_double_sharp',
  E_DOUBLE_FLAT: 'e_double_flat',
  E_FLAT: 'e_flat',
  E: 'e',
  E_SHARP: 'e_sharp',
  F_FLAT: 'f_flat',
  F: 'f',
  F_SHARP: 'f_sharp',
  F_DOUBLE_SHARP: 'f_double_sharp',
  G_FLAT: 'g_flat',
  G: 'g',
  G_SHARP: 'g_sharp',
  G_DOUBLE_SHARP: 'g_double_sharp',
}

export const NOTES = [
  {
    name: NOTE_NAMES.A_DOUBLE_FLAT,
    label: 'Abb',
    equivalents: [
      NOTE_NAMES.G,
      NOTE_NAMES.F_DOUBLE_SHARP,
    ],
  },
  {
    name: NOTE_NAMES.A_FLAT,
    label: 'Ab',
    hasScales: true,
    equivalents: [
      NOTE_NAMES.G_SHARP,
    ],
  },
  {
    name: NOTE_NAMES.A,
    label: 'A',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.G_DOUBLE_SHARP,
      NOTE_NAMES.B_DOUBLE_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.A_SHARP,
    label: 'A#',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.B_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.B_DOUBLE_FLAT,
    label: 'Bbb',
    equivalents: [
      NOTE_NAMES.A,
      NOTE_NAMES.G_DOUBLE_SHARP,
    ],
  },
  {
    name: NOTE_NAMES.B_FLAT,
    label: 'Bb',
    hasScales: true,
    equivalents: [
      NOTE_NAMES.A_SHARP,
    ],
  },
  {
    name: NOTE_NAMES.B,
    label: 'B',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.C_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.B_SHARP,
    label: 'B#',
    equivalents: [
      NOTE_NAMES.C,
      NOTE_NAMES.D_DOUBLE_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.C_FLAT,
    label: 'Cb',
    equivalents: [
      NOTE_NAMES.B,
    ],
  },
  {
    name: NOTE_NAMES.C,
    label: 'C',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.D_DOUBLE_FLAT,
      NOTE_NAMES.B_SHARP
    ],
  },
  {
    name: NOTE_NAMES.C_SHARP,
    label: 'C#',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.D_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.C_DOUBLE_SHARP,
    label: 'Cx',
    equivalents: [
      NOTE_NAMES.D,
      NOTE_NAMES.E_DOUBLE_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.D_DOUBLE_FLAT,
    label: 'Dbb',
    equivalents: [
      NOTE_NAMES.C,
      NOTE_NAMES.B_SHARP,
    ],
  },
  {
    name: NOTE_NAMES.D_FLAT,
    label: 'Db',
    hasScales: true,
    equivalents: [
      NOTE_NAMES.C_SHARP,
    ],
  },
  {
    name: NOTE_NAMES.D,
    label: 'D',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.C_DOUBLE_SHARP,
      NOTE_NAMES.E_DOUBLE_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.D_SHARP,
    label: 'D#',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.E_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.D_DOUBLE_SHARP,
    label: 'Dx',
    equivalents: [
      NOTE_NAMES.E,
      NOTE_NAMES.F_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.E_DOUBLE_FLAT,
    label: 'Ebb',
    equivalents: [
      NOTE_NAMES.D,
      NOTE_NAMES.C_DOUBLE_SHARP,
    ],
  },
  {
    name: NOTE_NAMES.E_FLAT,
    label: 'Eb',
    hasScales: true,
    equivalents: [
      NOTE_NAMES.D_SHARP,
    ],
  },
  {
    name: NOTE_NAMES.E,
    label: 'E',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.D_DOUBLE_SHARP,
      NOTE_NAMES.F_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.E_SHARP,
    label: 'E#',
    equivalents: [
      NOTE_NAMES.F,
    ],
  },
  {
    name: NOTE_NAMES.F,
    label: 'F',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.D_DOUBLE_SHARP,
      NOTE_NAMES.E_SHARP,
    ],
  },
  {
    name: NOTE_NAMES.F_FLAT,
    label: 'Fb',
    equivalents: [
      NOTE_NAMES.E,
    ],
  },
  {
    name: NOTE_NAMES.F_SHARP,
    label: 'F#',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.G_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.F_DOUBLE_SHARP,
    label: 'Fx',
    equivalents: [
      NOTE_NAMES.G,
      NOTE_NAMES.A_DOUBLE_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.G_FLAT,
    label: 'Gb',
    hasScales: true,
    equivalents: [
      NOTE_NAMES.F_SHARP,
    ],
  },
  {
    name: NOTE_NAMES.G,
    label: 'G',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.F_DOUBLE_SHARP,
      NOTE_NAMES.A_DOUBLE_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.G_SHARP,
    label: 'G#',
    tuning: true,
    hasScales: true,
    equivalents: [
      NOTE_NAMES.A_FLAT,
    ],
  },
  {
    name: NOTE_NAMES.G_DOUBLE_SHARP,
    label: 'Gx',
    equivalents: [
      NOTE_NAMES.A,
      NOTE_NAMES.B_DOUBLE_FLAT,
    ],
  },
];
