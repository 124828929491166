import { NOTE_NAMES } from './notes';

export const INTERVAL_NAMES = {
  ROOT: 'root',
  MINOR_SECOND: 'minor_second',
  MAJOR_SECOND: 'major_second',
  MINOR_THIRD: 'minor_third',
  MAJOR_THIRD: 'major_third',
  PERFECT_FOURTH: 'perfect_fourth',
  AUGMENTED_FOURTH: 'augmented_fourth',
  DIMINISHED_FIFTH: 'diminished_fifth',
  PERFECT_FIFTH: 'perfect_fifth',
  MINOR_SIXTH: 'minor_sixth',
  MAJOR_SIXTH: 'major_sixth',
  MINOR_SEVENTH: 'minor_seventh',
  MAJOR_SEVENTH: 'major_seventh',
};

export const INTERVALS = [
  {
    name: INTERVAL_NAMES.ROOT,
    label: '1',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.A_FLAT },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.A_SHARP },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.B_FLAT },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.B },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.C_SHARP },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.D_FLAT },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.D_SHARP, },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.E_FLAT },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.F_SHARP },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.G_FLAT },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.G_SHARP },
    ],
  },
  {
    name: INTERVAL_NAMES.MINOR_SECOND,
    label: 'b2',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.B_DOUBLE_FLAT },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.B_FLAT },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.B },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.C_FLAT },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.D_FLAT },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.E_DOUBLE_FLAT },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.E_FLAT },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.F_FLAT },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.G_FLAT },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.A_DOUBLE_FLAT },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.A_FLAT },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.A },
    ],
  },
  {
    name: INTERVAL_NAMES.MAJOR_SECOND,
    label: '2',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.B_FLAT },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.B },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.B_SHARP },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.C_SHARP },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.D_SHARP },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.E_FLAT },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.E_SHARP },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.F_SHARP },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.G_SHARP },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.A_FLAT },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.A_SHARP },
    ],
  },
  {
    name: INTERVAL_NAMES.MINOR_THIRD,
    label: 'b3',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.C_FLAT },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.C_SHARP },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.D_FLAT },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.E_FLAT },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.F_FLAT },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.F_SHARP },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.G_FLAT },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.A_FLAT },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.B_DOUBLE_FLAT },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.B_FLAT },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.B },
    ],
  },
  {
    name: INTERVAL_NAMES.MAJOR_THIRD,
    label: '3',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.C_SHARP },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.C_DOUBLE_SHARP },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.D_SHARP },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.E_SHARP },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.F_SHARP },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.F_DOUBLE_SHARP },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.G_SHARP },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.A_SHARP },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.B_FLAT },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.B },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.B_SHARP },
    ],
  },
  {
    name: INTERVAL_NAMES.PERFECT_FOURTH,
    label: '4',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.D_FLAT },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.D_SHARP },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.E_FLAT },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.F_SHARP },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.G_FLAT },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.G_SHARP },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.A_FLAT },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.B_FLAT },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.B },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.C_FLAT },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.C_SHARP },
    ],
  },
  {
    name: INTERVAL_NAMES.AUGMENTED_FOURTH,
    label: '#4',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.D_SHARP },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.D_DOUBLE_SHARP },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.E_SHARP },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.F_SHARP },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.F_DOUBLE_SHARP },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.G_SHARP },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.G_DOUBLE_SHARP },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.A_SHARP },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.B },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.B_SHARP },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.C_SHARP },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.C_DOUBLE_SHARP },
    ],
  },
  {
    name: INTERVAL_NAMES.DIMINISHED_FIFTH,
    label: 'b5',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.E_DOUBLE_FLAT },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.E_FLAT },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.F_FLAT },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.G_FLAT },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.A_DOUBLE_FLAT},
      { key: NOTE_NAMES.D, note: NOTE_NAMES.A_FLAT },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.B_DOUBLE_FLAT},
      { key: NOTE_NAMES.E, note: NOTE_NAMES.B_FLAT },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.C_FLAT },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.D_DOUBLE_FLAT},
      { key: NOTE_NAMES.G, note: NOTE_NAMES.D_FLAT },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.D },
    ],
  },
  {
    name: INTERVAL_NAMES.PERFECT_FIFTH,
    label: '5',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.E_FLAT },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.E_SHARP },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.F_SHARP },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.G_SHARP },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.A_FLAT },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.A_SHARP },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.B_FLAT },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.B },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.C_SHARP },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.D_FLAT },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.D_SHARP },
    ],
  },
  {
    name: INTERVAL_NAMES.MINOR_SIXTH,
    label: 'b6',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.F_FLAT },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.F_SHARP },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.G_FLAT },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.A_FLAT },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.B_DOUBLE_FLAT },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.B_FLAT },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.B },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.C_FLAT },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.D_FLAT },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.E_DOUBLE_FLAT },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.E_FLAT },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.E },
    ],
  },
  {
    name: INTERVAL_NAMES.MAJOR_SIXTH,
    label: '6',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.F_SHARP },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.F_DOUBLE_SHARP },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.G_SHARP },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.A_SHARP },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.B_FLAT },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.B },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.B_SHARP },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.C_SHARP },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.D_SHARP },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.E_FLAT },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.E_SHARP },
    ],
  },
  {
    name: INTERVAL_NAMES.MINOR_SEVENTH,
    label: 'b7',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.G_FLAT },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.G_SHARP },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.A_FLAT },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.B_FLAT },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.B },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.C_FLAT },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.C_SHARP },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.D_FLAT },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.E_FLAT },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.F_FLAT },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.F_SHARP },
    ],
  },
  {
    name: INTERVAL_NAMES.MAJOR_SEVENTH,
    label: '7',
    notes: [
      { key: NOTE_NAMES.A_FLAT, note: NOTE_NAMES.G },
      { key: NOTE_NAMES.A, note: NOTE_NAMES.G_SHARP },
      { key: NOTE_NAMES.A_SHARP, note: NOTE_NAMES.G_DOUBLE_SHARP },
      { key: NOTE_NAMES.B_FLAT, note: NOTE_NAMES.A },
      { key: NOTE_NAMES.B, note: NOTE_NAMES.A_SHARP },
      { key: NOTE_NAMES.C, note: NOTE_NAMES.B },
      { key: NOTE_NAMES.C_SHARP, note: NOTE_NAMES.B_SHARP },
      { key: NOTE_NAMES.D_FLAT, note: NOTE_NAMES.C },
      { key: NOTE_NAMES.D, note: NOTE_NAMES.C_SHARP },
      { key: NOTE_NAMES.D_SHARP, note: NOTE_NAMES.C_DOUBLE_SHARP },
      { key: NOTE_NAMES.E_FLAT, note: NOTE_NAMES.D },
      { key: NOTE_NAMES.E, note: NOTE_NAMES.D_SHARP },
      { key: NOTE_NAMES.F, note: NOTE_NAMES.E },
      { key: NOTE_NAMES.F_SHARP, note: NOTE_NAMES.E_SHARP },
      { key: NOTE_NAMES.G_FLAT, note: NOTE_NAMES.F },
      { key: NOTE_NAMES.G, note: NOTE_NAMES.F_SHARP },
      { key: NOTE_NAMES.G_SHARP, note: NOTE_NAMES.F_DOUBLE_SHARP },
    ],
  },
];
