import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import queryString from 'query-string';
import './app.css';
import NoteSelect from '../../../src/components/note-select/note-select';
import ScaleSelect from '../../../src/components/scale-select/scale-select';
import TuningSelect from '../../../src/components/tuning-select/tuning-select';
import NotesIntervalsToggle from '../../../src/components/notes-intervals-toggle/notes-intervals-toggle';
import HandToggle from '../../../src/components/hand-toggle/hand-toggle';
import String from '../../../src/components/string/string';
import { Analytics } from "@vercel/analytics/react";
import HelpModal from '../../../src/components/help-modal/help-modal';
import { getFrets } from '../../../src/services/get-frets/get-frets';
import { TUNINGS } from '../../../src/constants/tunings';

class App extends Component {
  handleNoteChange = (event) => {
    this.props.dispatch({
      type: 'NOTE',
      note: event.target.value,
    });
  }

  handleScaleChange = (event) => {
    this.props.dispatch({
      type: 'SCALE',
      scale: event.target.value,
    });
  }

  handleTuningChange = (event) => {
    this.props.dispatch({
      type: 'TUNING',
      tuning: event.target.value,
    });

    this.handleStringsChange(event.target.value);
  }

  handleStringsChange = (tuning) => {
    this.props.dispatch({
      type: 'STRINGS',
      strings: _.find(TUNINGS, { name: tuning }).notes,
    });
  }

  handleNotesIntervalsChange = (event) => {
    this.props.dispatch({
      type: 'TOGGLE',
      toggle: event.target.value,
    });
  }

  handleHandChange = (event) => {
    this.props.dispatch({
      type: 'HAND_TOGGLE',
      handToggle: event.target.value,
    });
  }

  showHelpModal = () => {
    this.props.dispatch({
      type: 'IS_MODAL_VISIBLE',
      isModalVisible: true,
    });
  }

  hideHelpModal = () => {
    this.props.dispatch({
      type: 'IS_MODAL_VISIBLE',
      isModalVisible: false,
    });
  }

  handleModalClick = (event) => {
    if (event.target.id === 'help-modal') {
      this.hideHelpModal();
    }
  }

  componentDidUpdate = () => {
    const {
      handToggle,
      note,
      scale,
      toggle,
      tuning,
    } = this.props;

    const params = {
      hand: handToggle,
      note,
      scale,
      toggle,
      tuning,
    };

    window.history.replaceState(
      {},
      document.title,
      `${ window.location.origin }?${ queryString.stringify(params) }`
    );

    if (window.ga) {
      window.ga('send', 'pageview', `${window.location.pathname}${window.location.search}`);
    }
  }

  componentDidMount = () => {
    const params = queryString.parse(window.location.search);

    const {
      hand: handToggle,
      note,
      scale,
      toggle,
      tuning,
    } = params;

    if (note) {
      this.props.dispatch({
        type: 'NOTE',
        note,
      });
    }

    if (scale) {
      this.props.dispatch({
        type: 'SCALE',
        scale,
      });
    }

    if (tuning) {
      this.props.dispatch({
        type: 'TUNING',
        tuning,
      });

      this.handleStringsChange(tuning);
    }

    if (toggle) {
      this.props.dispatch({
        type: 'TOGGLE',
        toggle,
      });
    }

    if (handToggle) {
      this.props.dispatch({
        type: 'HAND_TOGGLE',
        handToggle,
      });
    }
  }

  render() {
    const state = this.props;

    return (
      <div className='app'>
        <Analytics />
        {
          state.isModalVisible ?
          <HelpModal onClick={ this.handleModalClick }
          onClose={ this.hideHelpModal } /> : null
        }

        <div className='app__container'>
          <div className='app__nav u-flex-none u-flex-direction-row'>
            <div className='u-flex-direction-row'>
              <NoteSelect value={ state.note } onChange={ this.handleNoteChange } />
              <ScaleSelect value={ state.scale } onChange={ this.handleScaleChange } />
            </div>

            <div className='u-flex-none u-flex-direction-row'>
              <div className="app__tuning-select--nav u-margin-Rxl">
                <TuningSelect value={ state.tuning } onChange={ this.handleTuningChange } />
              </div>

              <div className="app__hand-toggle--nav u-margin-Rxl">
                <HandToggle value={ state.handToggle } onChange={ this.handleHandChange }/>
              </div>

              <NotesIntervalsToggle value={ state.toggle } onChange={ this.handleNotesIntervalsChange }/>
            </div>
          </div>

          <div className='app__fretboard'>
            <div className='app__fretboard-strings'>
              <div className={ classNames('strings', {
                'strings--left-handed': state.handToggle === 'left',
              }) }>
                { state.strings.map((string, index) => {
                  return (
                    <String key={ index }
                    frets={ getFrets(state.note, state.scale, string) }/>
                  );
                }) }
              </div>

              <div className='app__fretboard-markers-container'>
                <div className={ classNames('app__fretboard-markers', {
                  'app__fretboard-markers--left-handed': state.handToggle === 'left',
                }) }>
                  <div className='fret fret--marker u-flex-direction-row'>
                    <div className='u-flex-none fret__dot fret__dot--open'></div>
                  </div>

                  <div className='fret fret--marker u-flex-direction-row'></div>
                  <div className='fret fret--marker u-flex-direction-row'></div>

                  <div className='fret fret--marker u-flex-direction-row'>
                    <div className='u-flex-none fret__dot'></div>
                  </div>

                  <div className='fret fret--marker u-flex-direction-row'></div>

                  <div className='fret fret--marker u-flex-direction-row'>
                    <div className='u-flex-none fret__dot'></div>
                  </div>

                  <div className='fret fret--marker u-flex-direction-row'></div>

                  <div className='fret fret--marker u-flex-direction-row'>
                    <div className='u-flex-none fret__dot'></div>
                  </div>

                  <div className='fret fret--marker u-flex-direction-row'></div>

                  <div className='fret fret--marker u-flex-direction-row'>
                    <div className='u-flex-none fret__dot'></div>
                  </div>

                  <div className='fret fret--marker u-flex-direction-row'></div>
                  <div className='fret fret--marker u-flex-direction-row'></div>

                  <div className='fret fret--marker u-flex-direction-row'>
                    <div className='u-flex-none fret__dot'></div>
                    <div className='u-flex-none fret__dot'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="app__bottom-nav u-flex-direction-row">
            <div className="app__tuning-select--bottom-nav">
              <TuningSelect value={ state.tuning } onChange={ this.handleTuningChange } />
            </div>

            <div className="app__hand-toggle--bottom-nav u-flex-none">
              <HandToggle value={ state.handToggle } onChange={ this.handleHandChange }/>
            </div>
          </div>
        </div>

        <div className='app__help-icon' onClick={ this.showHelpModal }>?</div>
      </div>
    );
  }
}

// Map state to props
const mapStateToProps = (state) => ({
  handToggle: state.handToggle,
  note: state.note,
  scale: state.scale,
  toggle: state.toggle,
  tuning: state.tuning,
  strings: state.strings,
  isModalVisible: state.isModalVisible,
});

// Connect the App component to the Redux store
export default connect(mapStateToProps)(App);
