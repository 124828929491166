export const TUNINGS = [
  {
    name: 'standard',
    label: 'Standard — E A D G B E',
    notes: ['e', 'a', 'd', 'g', 'b', 'e'],
  },
  {
    name: 'drop_d',
    label: 'Drop D — D A D G B E',
    notes: ['d', 'a', 'd', 'g', 'b', 'e'],
  },
  {
    name: 'open_a',
    label: 'Open A — E A E A C# E',
    notes: ['e', 'a', 'e', 'a', 'c_sharp', 'e'],
  },
  {
    name: 'open_c',
    label: 'Open C — C G C G C E',
    notes: ['c', 'g', 'c', 'g', 'c', 'e'],
  },
  {
    name: 'open_d',
    label: 'Open D — D A D F# A D',
    notes: ['d', 'a', 'd', 'f_sharp', 'a', 'd'],
  },
  {
    name: 'open_g',
    label: 'Open G — D G D G B D',
    notes: ['d', 'g', 'd', 'g', 'b', 'd'],
  }
];
