import { combineReducers } from 'redux';
import toggle from './toggle';
import handToggle from './hand-toggle';
import note from './note';
import tuning from './tuning';
import scale from './scale';
import strings from './strings';
import isModalVisible from './is-modal-visible';

const reducers = combineReducers({
  toggle,
  handToggle,
  note,
  tuning,
  scale,
  strings,
  isModalVisible,
});

export default reducers;
