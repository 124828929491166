import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import './fret.css';
import PropTypes from 'prop-types';

class Fret extends Component {
  render() {
    const IS_EMPTY_FRET = !this.props.isVisible && !this.props.isHighlighted && !this.props.isTriad;

    return (
      <div className='fret'>
        <div className={classNames('fret__label u-flex-none', {
          'fret__label--visible': this.props.isVisible,
          'fret__label--highlighted': this.props.isHighlighted,
          'fret__label--triad': this.props.isTriad,
          'fret__label--empty': IS_EMPTY_FRET,
        })}>
          <div className={classNames('fret__label__inner', {
            'fret__label__inner--visible': this.props.isVisible,
            'fret__label__inner--highlighted': this.props.isHighlighted,
            'fret__label__inner--triad': this.props.isTriad,
            'fret__label__inner--empty': IS_EMPTY_FRET,
          })}>
            {
              this.props.toggle === 'notes' ?
                <span className={classNames({
                  'fret__label__small-text': _.includes(this.props.note, 'bb'),
                })}>{ this.props.note }</span> :
                this.props.interval
            }
          </div>
        </div>

        <div className='fret__divider'></div>
      </div>
    );
  }
}

Fret.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  isTriad: PropTypes.bool.isRequired,
  note: PropTypes.string.isRequired,
  interval: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  toggle: PropTypes.string.isRequired, // This comes from Redux state
};

// Map state to props
const mapStateToProps = (state) => ({
  toggle: state.toggle,
});

// Connect the component to Redux
export default connect(mapStateToProps)(Fret);
